import * as React from 'react';
import PropTypes from 'prop-types';

import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import AutoAwesomeRoundedIcon from '@mui/icons-material/AutoAwesomeRounded';
import { styled } from '@mui/system';

import AppAppBar from './components/AppAppBar';
import Hero from './components/Hero';
import Highlights from './components/Highlights';
import Features from './components/Features';
import FAQ from './components/FAQ';
import Footer from './components/Footer';

import heroImg from './assets/hero-backgroud-low.png'
const heroStyle = {backgroundImage: `url(${heroImg})`, backgroundPosition: 'center', width: '1500px', height: '642px'}
const heroWrapper = {position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center',}
const heroContainer = {position: 'absolute', width: '100%', zIndex: '0'}

function ToggleCustomTheme({ showCustomTheme, toggleCustomTheme }) {}
const toggleColorMode = () => {
  return 'dark'
};

ToggleCustomTheme.propTypes = {
  showCustomTheme: PropTypes.shape({
    valueOf: PropTypes.func.isRequired,
  }).isRequired,
  toggleCustomTheme: PropTypes.func.isRequired,
};

export default function LandingPage() {
  const [mode] = React.useState('dark');
  const defaultTheme = createTheme({ palette: { mode } });

  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <AppAppBar mode={mode} toggleColorMode={toggleColorMode} />
      <Hero />
      <Box sx={{ bgcolor: 'background.default' }}>
        <Features />
        <Divider />
        <Highlights />
        <Divider />
        <Footer />
      </Box>
      <div className='heroContainer' style={heroContainer}>
        <div style={heroWrapper} >
          <div style={heroStyle}></div>
        </div>
      </div>
      <style>
        {`
          @media (max-width: 600px) {
            .heroContainer {
              top: -228px;
            }
          }
          @media (min-width: 601px) {
            .heroContainer {
              top: -164px;
            }
          }
          /* Add more media queries as needed for different screen sizes */
        `}
      </style>
    </ThemeProvider>
  );
}
