import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Chip from "@mui/material/Chip";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import DevicesRoundedIcon from "@mui/icons-material/DevicesRounded";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
import TerminalIcon from "@mui/icons-material/Terminal";

import apocImage from "../assets/apocalypic-adventure-episode-1-feature.jpg";
import pecciaImage from "../assets/collaboration-feature.jpg";
import apdictImage from "../assets/ascenzio-prediction-apdict-feature.jpg";

const items = [
  {
    icon: <SportsEsportsIcon />,
    title: "Apocalyptic Adventure",
    description:
      "Immerse yourself in Apocalyptic Adventure, a thrilling VR experience that blends action, adventure, and resource management. You'll need to fight off enemies, traverse treacherous environments, and craft effective defenses to survive. Each episode presents a unique challenge in a different location, so put your skills to the test!",
    imageDark: `url(${apocImage})`,
    moreLabel: 'Visit ApocalypticAdventure.com',
    url: 'https://store.steampowered.com/app/1726860/Apocalyptic_Adventure_Episode_1/'
  },
  {
    icon: <TerminalIcon />,
    title: "Ascenzio Prediction (APdict)",
    description:
      "APdict: Intelligent assistant for informed decision-making, integrating language models and software engineering for insightful analysis and recommendations.",
    imageDark: `url(${apdictImage})`,
    moreLabel: 'Read on Medium.com',
    url: 'https://medium.com/@ascenzio/the-evolution-of-software-engineering-integrating-language-models-into-autonomous-agents-305dac7b2d63'
  },
  {
    icon: <DevicesRoundedIcon />,
    title: "Interested?",
    description:
      "Are you passionate about cutting-edge technology and creative innovation? Join me in pushing the boundaries of technology and storytelling. Let's collaborate and shape the future together!",
    imageDark: `url(${pecciaImage})`,
    moreLabel: 'Visit Peccia.net',
    url: 'https://www.peccia.net'
  },
];

const featureContainer = {
  padding: "32px 32px 64px 32px",
  background: `rgba(150, 150, 150, 0.25)`
};

export default function Features() {
  const [selectedItemIndex, setSelectedItemIndex] = React.useState(0);

  const handleItemClick = (index) => {
    setSelectedItemIndex(index);
  };

  const handleNavigation = (index) => {
    window.location.href = items[index].url
  }

  const selectedFeature = items[selectedItemIndex];

  return (
    <Container id="features" sx={{ py: { xs: 8, sm: 16 } }}>
      <div style={featureContainer}>
        <Grid container spacing={6}>
          <Grid item xs={12} md={12}>
            <Box sx={{ width: "100%" }}>
              <Typography component="h2" variant="h4" color="text.primary">
                Are you ready to experience the future of digital reality?
              </Typography>
              <Typography
                variant="body1"
                color="text.secondary"
                sx={{ mb: { xs: 2, sm: 4 }, marginTop: "16px" }}
              >
                Ascenzio's recent accomplishments include the creation of
                APdict, an autonomous agent system that will revolutionize the
                way we interact with language, and the ongoing development of
                Apocalyptic Adventure, a thrilling Virtual Reality game that
                will transport you to another world.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid
              container
              item
              gap={1}
              sx={{ display: { xs: "auto", sm: "none" } }}
            >
              {items.map(({ title }, index) => (
                <Chip
                  key={index}
                  label={title}
                  onClick={() => handleItemClick(index)}
                  sx={{
                    borderColor:
                      selectedItemIndex === index ? "primary.light" : "",
                    background: selectedItemIndex === index ? "none" : "",
                    backgroundColor:
                      selectedItemIndex === index ? "primary.main" : "",
                    "& .MuiChip-label": {
                      color: selectedItemIndex === index ? "#fff" : "",
                    },
                  }}
                />
              ))}
            </Grid>
            <Box
              component={Card}
              variant="outlined"
              sx={{
                display: { xs: "auto", sm: "none" },
                mt: 4,
              }}
            >
              <Box
                sx={{
                  backgroundImage: items[selectedItemIndex].imageDark,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  minHeight: 280,
                }}
              />
              <Box sx={{ px: 2, pb: 2 }}>
                <Typography
                  color="text.primary"
                  variant="body2"
                  fontWeight="bold"
                >
                  {selectedFeature.title}
                </Typography>
                <Typography
                  color="text.secondary"
                  variant="body2"
                  sx={{ my: 0.5 }}
                >
                  {selectedFeature.description}
                </Typography>
                <Link
                  color="primary"
                  variant="body2"
                  fontWeight="bold"
                  sx={{
                    display: "inline-flex",
                    alignItems: "center",
                    "& > svg": { transition: "0.2s" },
                    "&:hover > svg": { transform: "translateX(2px)" },
                  }}
                  onClick={(event) => {
                    event.stopPropagation();
                    window.location.href = selectedFeature.url
                  }}
                >
                  <span>{selectedFeature.title}</span>
                  <ChevronRightRoundedIcon
                    fontSize="small"
                    sx={{ mt: "1px", ml: "2px" }}
                  />
                </Link>
              </Box>
            </Box>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="flex-start"
              spacing={2}
              useFlexGap
              sx={{ width: "100%", display: { xs: "none", sm: "flex" } }}
            >
              {items.map(({ icon, title, description, moreLabel = null }, index) => (
                <Card
                  key={index}
                  variant="outlined"
                  component={Button}
                  onClick={() => handleItemClick(index)}
                  sx={{
                    p: 3,
                    height: "fit-content",
                    width: "100%",
                    background: "none",
                    backgroundColor:
                      selectedItemIndex === index
                        ? "action.selected"
                        : undefined,
                    borderColor:
                      selectedItemIndex === index ? "primary.dark" : "grey.800",
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      textAlign: "left",
                      flexDirection: { xs: "column", md: "row" },
                      alignItems: { md: "center" },
                      gap: 2.5,
                    }}
                  >
                    <Box
                      sx={{
                        color: (theme) => {
                          return selectedItemIndex === index
                            ? "primary.main"
                            : "grey.700";
                        },
                      }}
                    >
                      {icon}
                    </Box>
                    <Box sx={{ textTransform: "none" }}>
                      <Typography
                        color="text.primary"
                        variant="body2"
                        fontWeight="bold"
                      >
                        {title}
                      </Typography>
                      <Typography
                        color="text.secondary"
                        variant="body2"
                        sx={{ my: 0.5 }}
                      >
                        {description}
                      </Typography>
                      <Link
                        color="primary"
                        variant="body2"
                        fontWeight="bold"
                        sx={{
                          display: "inline-flex",
                          alignItems: "center",
                          "& > svg": { transition: "0.2s" },
                          "&:hover > svg": { transform: "translateX(2px)" },
                        }}
                        onClick={(event) => {
                          event.stopPropagation();
                          handleNavigation(index);
                        }}
                      >
                        <span>{moreLabel}</span>
                        <ChevronRightRoundedIcon
                          fontSize="small"
                          sx={{ mt: "1px", ml: "2px" }}
                        />
                      </Link>
                    </Box>
                  </Box>
                </Card>
              ))}
            </Stack>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{ display: { xs: "none", sm: "flex" }, width: "100%" }}
          >
            <Card
              variant="outlined"
              sx={{
                height: "100%",
                width: "100%",
                display: { xs: "none", sm: "flex" },
                pointerEvents: "none",
              }}
            >
              <Box
                sx={{
                  m: "auto",
                  width: 400,
                  height: 500,
                  backgroundSize: "contain",
                  backgroundImage: items[selectedItemIndex].imageDark,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                }}
              />
            </Card>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
}
